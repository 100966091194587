// ISSUE:
// When the text content becomes longer,
// the whole header including the image becomes taller,
// and the diagonal line/clip-path becomes more steep
// which means the image-diagonal comes closer/ too close to the text.
// Can't set a set height when we don't know how much text content there will be.
//

.header-subpage {
    position: relative;

    @include media-breakpoint-up(lg) {
        min-height: 555px;
    }

    &__image {
        height: 100%;
        width: 100%;
        clip-path: polygon(100% 0, 0 0, 100% 100%);

        @include media-breakpoint-up(lg) {
            width: 60%;
            position: absolute;
            top: 0;
            right: 0;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__main {
        padding-block: 10%;
        position: relative;
        z-index: 2;
        margin-top: -60px;

        @include media-breakpoint-up(lg) {
            margin: 0;
            padding-block: 100px;
            max-width: 590px;
            padding-right: 50px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 660px;
        }
    }

    &__inner {
        @include media-breakpoint-up(lg) {
            margin-right: 45px;
        }
    }

    &__title {
        color: var(--secondary);
        font-size: 40px;

        @include media-breakpoint-up(md) {
            font-size: 50px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 60px;
        }
    }

    &__intro {
        font-size: 24px;
        line-height: 1.4em;
        font-weight: var(--fw-light);

        @include media-breakpoint-up(md) {
            font-size: 30px;
        }
    }
}
