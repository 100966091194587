.section-team {
    position: relative;
    height: 560px;

    &__main {
        position: relative;
        z-index: 2;
    }

    &__title {
        color: #fcb017;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        picture {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.section-approach {
    p {
        @extend .lead;
    }
}

.section-wrapper {
    p {
        color: white;
    }

    .section-title,
    p.section-title {
        font-size: 30px;
        line-height: 107%;
        font-weight: var(--fw-light);

        @media screen and (min-width: 768px) {
            font-size: 50px;
        }
    }

    .section-sub-title,
    p.section-sub-title {
        font-size: 25px;
        line-height: 107%;
        font-weight: var(--fw-light);

        @media screen and (min-width: 768px) {
            font-size: 50px;
        }
    }

    &.section-investment {
        background: #28292b url("/src/images/images/investment-small.jpg") no-repeat center center;
        background-size: cover;
        min-height: 558px;
        padding-bottom: 75px;

        ul {
            background: var(--gray-light);
            display: flex;
            margin: 0 15px;
            padding: 0;
            flex-direction: column;
            padding: 40px;

            li {
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                margin-bottom: 40px;
                font-size: 20px;
                line-height: 140%;

                img {
                    width: 100px;
                    height: 100px;
                }
            }

            li:last-child {
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: 768px) {
            background: #28292b url("/src/images/images/investment.jpg") no-repeat bottom center;
            background-size: cover;
            min-height: 600px;
            width: 100%;

            ul {
                flex-direction: row;
                margin: 0;

                li {
                    width: 25%;
                    margin-bottom: 0;
                }
            }
        }

        @media screen and (min-width: 2000px) {
            background: #28292b url("/src/images/images/investment-xxl.jpg") no-repeat bottom center;
            background-size: cover;
            min-height: 600px;
            width: 100%;
        }
    }
}

.section {
    padding-top: 100px;
    padding-bottom: 100px;

    &.no-pad {
        padding-top: 0;
    }

    > .row {
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin: 0 auto;
            max-width: 95%;
        }
    }

    @media screen and (min-width: 768px) {
        padding-top: 80px;
        padding-bottom: 80px;

        > .row {
            > div {
                max-width: 750px;
            }
        }
    }
}

.section-halfpad {
    padding-block: 40px;

    @include media-breakpoint-up(md) {
        padding-block: 50px;
    }
}

.section-nopad-btm {
    padding-bottom: 0;
}
