.card-grid {
    --card-grid-gap: 2rem;
    @include media-breakpoint-up(lg) {
        --card-grid-gap: 4rem;
    }

    display: grid;
    gap: var(--card-grid-gap);

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
        gap: var(--card-grid-gap);
    }

    .card {
        width: 100%;

        // &:first-of-type {
        //     @extend .card--split;
        //     grid-column: 1 / -1;
        // }
    }
}

.card {
    display: flex;
    flex-direction: column;
    padding: 0;

    &__main {
        padding: 35px 25px;
        background: var(--gray-lighter);
        display: flex;
        flex-direction: column;
        flex: 1;

        @include media-breakpoint-up(lg) {
            padding: 45px 35px;
        }
    }

    &__title {
        font-size: 30px;
        font-weight: var(--fw-light);
        margin-bottom: 0.8rem;

        @include media-breakpoint-up(lg) {
            font-size: 40px;
        }
    }

    &__desc {
        font-size: 20px;
        margin-bottom: 45px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 65px;
        }
    }

    .read-more {
        margin-top: auto;
    }

    &__image {
        height: 300px;

        @include media-breakpoint-up(md) {
            height: 370px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--split {
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .card {
            // name ref only

            &__main {
                @include media-breakpoint-up(md) {
                    width: 50%;
                }
            }

            &__image {
                @include media-breakpoint-up(md) {
                    // height: auto;
                    width: calc(50% - (var(--card-grid-gap) / 2));
                    order: 1;
                }
            }
        }
    }
}
