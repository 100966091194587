:root {
    --primary: #fcb017; // Epic Gold
    --gold: $primary;
    --secondary: #344c36; // Cellini Green
    --green: $secondary;
    --blush: #c35650;
    --stone: #59788e;

    --gray-light: #e5e5e5;
    --gray-lighter: #fafafa;

    --text-color: #17181a;

    // Containers
    --container-sm: 540px;
    --container-md: 720px;
    --container-lg: 960px;
    --container-xl: 1140px;
    --container-xxl: 1320px;

    // Navbar
    --sticky-nav-height: 75px;

    // Typography
    --fw-light: 300;
    --fw-normal: 400;
    --fw-regular: var(--fw-normal);
    --fw-bold: 600;
    --fw-strong: var(--fw-bold);
}
