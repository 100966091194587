.footer {
    font-size: 20px;

    &__logo {
        width: 150px;
        height: auto;
        padding-block: 40px;

        @include media-breakpoint-up(md) {
            width: 250px;
            padding-block: 75px;
        }
    }
}
