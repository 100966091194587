.read-more,
a.read-more {
    color: var(--text-color);
    font-weight: var(--fw-bold);
    text-decoration: none;

    @include media-breakpoint-up(lg) {
        font-size: 24px;
    }

    &::after {
        content: ">";
        color: var(--primary);
        padding-left: 5px;
    }

    &:hover,
    &:active,
    &:focus {
    }
}
