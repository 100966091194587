body {
    font-family: "Cairo";
    font-size: 20px;
    line-height: 140%;
    color: var(--text-color);

    // @media screen and (min-width: 768px) {
    //     font-size: 24px;
    // }
}
