.navbar-wrapper {
    background: var(--gray-lighter);

    @include media-breakpoint-up(md) {
        padding-block: 33px;
    }

    &--on-dark {
        background: transparent;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 992px) {
        flex-direction: row;
        align-items: center;
        color: var(--secondary);
    }

    &:hover {
        @media screen and (min-width: 992px) {
            color: var(--primary);
        }
    }

    &.sticky,
    &.menu-open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        background: var(--secondary);
        padding: 0;
        margin: 0;
        transition: all 0.5s ease;
        height: var(--sticky-nav-height);

        @media screen and (min-width: 768px) {
            padding: 0 30px;
        }
    }

    &.menu-open {
        .navbar__toggle {
            color: #fff;
        }

        ul {
            display: flex;
        }

        .navbar__brand {
            svg {
                height: 58px;
                .logo-shape,
                .logo-subtext {
                    fill: #fff;
                }
            }
        }
    }

    &__brand {
        padding: 10px 0 10px 20px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        // img,
        svg {
            width: 116px;

            @media screen and (min-width: 768px) {
                width: 140px;
            }
            @include media-breakpoint-up(lg) {
                width: 210px; // 140px;
            }
        }
    }

    &__toggle {
        // button
        box-shadow: none;
        border: none;
        background: transparent;

        color: var(--secondary);
        padding: 25px 30px 0 0;
        transition: all 0.5s ease;

        font-size: 18px;
        font-weight: var(--fw-bold);
        transition: all 0.3s ease-in-out;

        @media screen and (min-width: 992px) {
            display: none;
        }
    }
}

.navbar-list {
    display: none;

    @media screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: space-evenly;
        position: absolute;
        top: var(--sticky-nav-height);
        left: 0;
        right: 0;
        background-color: var(--primary);
        min-height: 375px;
    }

    @media screen and (min-width: 992px) {
        display: flex;
        margin: 0;
        padding: 0;
        padding-top: 32px;
    }

    &__item {
    }

    &__link {
        color: var(--secondary);
        text-decoration: none;
        padding: 10px 0 10px 20px;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: var(--fw-normal);
        transition: all 0.3s ease-in-out;

        &:link:hover,
        &:visited:hover {
            color: var(--secondary);
        }
    }
}
