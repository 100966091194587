.form-group {
}

.form-control,
textarea.form-control {
    border-color: var(--gray-light);
    border-radius: 0;
    padding: 16px 20px;
    font-size: 20px;
    font-weight: var(--fw-light);

    &::placeholder {
    }
}

.form-label {
    font-size: 14px;
    font-weight: var(--fw-bold);
}

.form-textarea {
    max-width: none !important;
}
