.two-up {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }
    @include media-breakpoint-up(lg) {
        gap: 60px;
    }
}

.text-card {
    --text-card-color: var(--text-color);
    background: var(--gray-lighter);
    display: flex;

    @include media-breakpoint-up(md) {
        justify-content: flex-end;
    }

    &__inner {
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: calc(var(--container-md) / 2 - 20px);
        }
        @include media-breakpoint-up(lg) {
            max-width: calc(var(--container-lg) / 2 - 30px);
        }
        @include media-breakpoint-up(xl) {
            max-width: calc(var(--container-xl) / 2 - 30px);
        }
        @include media-breakpoint-up(xxl) {
            max-width: calc(var(--container-xxl) / 2 - 30px);
        }
    }

    &__main {
        padding: 50px 35px;
        color: var(--text-card-color);
        display: flex;
        flex-direction: column;
        height: 100%;

        @include media-breakpoint-up(lg) {
            padding: 100px 40px;
        }
    }

    &__desc {
        margin-bottom: 45px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 65px;
        }
    }
    &__title {
        font-size: 32px;
        font-weight: var(--fw-light);
        margin-bottom: 0.6em;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
        }
    }

    a.read-more {
        color: var(--text-card-color);
        margin-top: auto;
    }

    &--left {
        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
    }
    &--right {
        @include media-breakpoint-up(md) {
            justify-content: flex-start;
        }
    }

    &--blush,
    &--stone,
    &--green {
        --text-card-color: #fff;
    }

    &--blush {
        background: #c35650;
    }
    &--stone {
        background: #59788e;
    }
    &--green {
        background: #344c36;
    }
    &--gold {
        background: #fcb017;

        a.readmore {
            &:focus,
            &:hover,
            &:active {
                color: var(--text-color);
            }
            &::after {
                color: var(--text-color);
            }
        }
    }
}
