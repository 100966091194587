.button {
    border: 1px solid black;
    border-radius: 0;
    font-size: 24px;
    font-weight: var(--fw-bold);
    padding: 15px 80px;
    background: transparent;
    color: var(--text-color);

    &:focus,
    &:hover,
    &:active {
        background-color: transparent;
    }

    &--primary {
        border-color: var(--primary);
    }
}
