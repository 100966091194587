.h2 {
    font-weight: var(--fw-light);
    font-size: 30px;
    line-height: 110%;
    margin-bottom: 30px;
    color: var(--secondary);

    @media screen and (min-width: 768px) {
        font-size: 50px;
    }
}

.h3 {
    background-color: var(--secondary);
    font-weight: var(--fw-normal);
    font-size: 24px;
    line-height: 120%;
    color: #fff;
    display: block;
    width: 100%;
    text-transform: uppercase;
    padding: 20px 30px;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        font-size: 30px;
    }
}

p {
    line-height: 1.4em;
}

p.lead {
    font-size: 20px;

    @include media-breakpoint-up(md) {
        font-size: 24px;
    }
}

.section-title,
p.section-title {
    color: var(--primary);
    font-size: 24px;
    line-height: 140%;
    text-transform: uppercase;
    margin-top: 60px;
    margin-bottom: 30px;
}

p.strong {
    font-weight: var(--fw-bold);
}

.lead {
    &--lg {
        font-size: 25px;

        @include media-breakpoint-up(md) {
            font-size: 30px;
        }
    }
}

a:link,
a:visited {
    // color: var(--primary);
    color: var(--secondary);
    transition: all 0.3s ease-in-out;

    // &.inverted {
    //     color: #231F20;
    // }

    &:hover.tel,
    &.tel {
        color: #231f20;
        text-decoration: none;
    }

    &:hover {
        // color: #231F20;
        color: var(--primary);

        // &.inverted {
        //     color: var(--primary);
        // }
    }
}
