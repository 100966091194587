.tricol {
    // margin-top: -450px;
    position: relative;
    z-index: 2;

    .section-title,
    p.section-title {
        font-weight: var(--fw-light);
        font-size: 24px;
        line-height: 140%;
        color: #231f20;
        margin: 14px 0;

        @media screen and (min-width: 768px) {
            font-size: 30px;
        }
    }

    .section-sub-title,
    p.section-sub-title {
        font-size: 22px;
        line-height: 140%;
        color: #231f20;

        @media screen and (min-width: 768px) {
            font-size: 25px;
        }
    }

    p {
        font-size: 20px;
    }

    br {
        @media screen and (max-width: 1199px) {
            display: none;
            clear: none;
        }
    }

    .row {
        > div:first-child {
            margin-top: -75px !important;
            margin-bottom: 75px;
        }

        .col {
            max-width: 768px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 30px;
            text-align: center;
            margin-bottom: 75px;
            margin-top: 0;

            @media screen and (min-width: 768px) {
                margin-top: -75px !important;
            }

            @media screen and (max-width: 992px) {
                margin-top: 0 !important;
            }

            p:last-child {
                margin-top: -16px;
            }
        }
    }
}
