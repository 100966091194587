ul.experience-list {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 0;
    flex-wrap: wrap;
    margin-bottom: 0;

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.3%;
        min-height: 120px;
        font-size: 16px;
        line-height: 130%;
        text-align: center;

        img {
            width: 48px !important;
            height: 48px !important;
        }

        @media screen and (min-width: 768px) {
            width: 120px;
        }
    }
}
