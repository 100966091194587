.article {
    h2,
    h3 {
        font-weight: var(--fw-light);
        line-height: 1.1em;
        margin-bottom: 0.8em;
    }
    h2 {
        font-size: 40px;
    }
    h3 {
        font-size: 30px;
    }
    h4 {
        font-size: 25px;
    }

    p {
        font-weight: var(--fw-light);
    }
}
