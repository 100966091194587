.image-circle {
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        width: 250px;
        height: 250px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
