.ecommerce {
    margin: 75px auto 0 auto;
    text-align: center;

    h2 {
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            margin: 40px 20px;
            max-width: 220px;

            a {
                text-decoration: none;

                &:focus,
                &:hover,
                &:active {
                    text-decoration: none;
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
