.header {
    // background: #28292b url("/src/images/images/header-small.jpg") no-repeat center center;
    // background-size: cover;
    background: #28292b;
    min-height: 426px;

    @media screen and (min-width: 768px) {
        // background: #28292b url("/src/images/images/header.jpg") no-repeat bottom center;
        // background-size: cover;
        min-height: 750px;
        width: 100%;
    }

    @media screen and (min-width: 2000px) {
        // background: #28292b url("/src/images/images/header-xxl.jpg") no-repeat bottom center;
        // background-size: cover;
        min-height: 750px;
        width: 100%;
    }

    .container {
        position: relative;
        padding: 0;

        > img {
            // THE BIG I
            position: absolute;
            top: 128px;
            right: 32px;
            width: auto;
            height: 388px;

            @include media-breakpoint-up(md) {
                top: 220px;
                right: 9px; // 12px
                height: 100%;
            }
        }
    }

    .outer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 426px;
        padding: 15px;

        @media screen and (min-width: 768px) {
            min-height: 750px;
        }

        .inner {
            display: flex;
            justify-content: space-between;

            &.menu-open,
            &.sticky {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 10;
                background: var(--secondary);
                padding: 0;
                margin: 0;
                transition: all 0.5s ease;
                height: var(--sticky-nav-height);

                @media screen and (min-width: 768px) {
                    padding: 0 30px;
                }
            }

            &.menu-open {
                ul {
                    display: flex;
                }
            }

            ul {
                display: none;

                @media screen and (max-width: 992px) {
                    flex-direction: column;
                    justify-content: space-evenly;
                    position: absolute;
                    top: var(--sticky-nav-height);
                    left: 0;
                    right: 0;
                    background-color: var(--primary);
                    min-height: 375px;
                }
            }

            .logo img {
                width: 116px;

                @media screen and (min-width: 768px) {
                    width: 140px;
                }
            }

            a.menu-toggle {
                color: white;
                display: block;
                padding: 25px 30px 0 0;
            }

            a {
                color: var(--secondary);
                text-decoration: none;
                padding: 10px 0 10px 20px;
                font-size: 18px;
                font-weight: var(--fw-bold);
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: white;
                }
            }

            @media screen and (min-width: 992px) {
                flex-direction: row;
                padding-top: 75px;
                align-items: flex-end;

                a.menu-toggle {
                    display: none;
                }

                ul {
                    display: flex;
                    margin: 0;
                    padding: 0;

                    li {
                        a {
                            color: #fff;

                            &:hover {
                                color: var(--primary);
                            }
                        }
                    }
                }
            }
        }

        .hero {
            width: 60%;
            margin-bottom: 50px;
            margin-left: 20px;

            h1 {
                color: white;
                font-weight: var(--fw-light);
                font-size: 30px;
                line-height: 110%;
                font-style: normal;
            }

            @media screen and (min-width: 768px) {
                width: 50%;
                margin-bottom: 150px;

                h1 {
                    font-size: 60px;
                }
            }
        }
    }
}
