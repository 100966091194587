.u-flex,
.flex {
    display: flex;
}

.gap-6 {
    gap: 4rem;
}

.text-textcolor {
    color: var(--text-color);
}

.text-max-width-700 {
    max-width: 700px;
}
.text-max-width-800 {
    max-width: 800px;
}

.block-center {
    display: block;
    margin-inline: auto;
}
