.text-comp-block {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
        gap: 3rem;
        align-items: start;
    }

    &__comp {
        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }
}
