.video-container {
    position: relative;

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        z-index: 0;
    }

    .caption {
        z-index: 1;
    }
}
