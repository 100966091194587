.text-card-with-image {
    position: relative;
    display: flex;
    flex-direction: column;

    &__main {
        display: flex;
    }

    .text-card {
        @include media-breakpoint-up(md) {
            width: calc(50% - 30px);
            position: relative;
            z-index: 2;
        }
    }

    .container {
        // holds image

        @include media-breakpoint-down(md) {
            order: 1; // main has 0 by default
        }
    }

    &__image {
        --image-height: 200px;
        height: var(--image-height);

        @include media-breakpoint-up(md) {
            --image-height: 400px;
            margin-top: calc(-1 * var(--image-height) / 2 - 30px); // -1 to get negative value
        }
        @include media-breakpoint-up(lg) {
            --image-height: 450px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--left {
    }

    &--right {
        .text-card-with-image__main {
            justify-content: flex-end;
        }

        .text-card-with-image__image {
            @include media-breakpoint-up(md) {
                margin-top: 0;
                margin-bottom: calc(-1 * var(--image-height) / 2 - 30px);
            }
        }
    }
}
