@import "reset"; // BS has reboot file, but this one does something to list items so keeping it for now
@import "../../../node_modules/@fontsource/cairo/index.css";
@import "../../../node_modules/@fontsource/cairo/300.css";
@import "../../../node_modules/@fontsource/cairo/600.css";
@import "bootstrap-import";

// VARIABLES
@import "utils/variables";

// BASE
@import "base/base";
@import "base/typography";
@import "base/buttons";
@import "base/form"; // BS

// ATOMS
@import "atoms/read-more";

// COMPONENTS
@import "components/navbar";
@import "components/article";
@import "components/card";
@import "components/text-card";
@import "components/text-card-with-image";
@import "components/ecommerce";
@import "components/experience-list";
@import "components/footer";
@import "components/header";
@import "components/image-circle";
@import "components/parallax";
@import "components/tricol";
@import "components/video-container";
@import "components/section";
@import "components/header-subpage";
@import "components/text-comp-block";
@import "components/full-width-image-banner";
@import "components/team";

// UTILS
@import "utils/utils";
